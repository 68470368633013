import type { FederationRuntimePlugin } from '@module-federation/enhanced/runtime';
import type { Remote, SharedGetter } from '@module-federation/runtime/types';

const useLocalShares = new Set();

type ManifestType = {
  mfe: {
    server: Remote[];
    client: Remote[];
  };
  ['shared-lib']: {
    [key: string]: {
      esm: string;
      cjs: string;
    };
  };
};

function getManifestFactory(platform: platformType) {
  const manifestPromise = async () => {
    let discoveryUrl = '';
    if (typeof process !== 'undefined' && process.env.NEXT_PUBLIC_MFE_SERVICE_DISCOVERY_URL) {
      discoveryUrl = process.env.NEXT_PUBLIC_MFE_SERVICE_DISCOVERY_URL;
      window.MFE_SERVICE_DISCOVERY_URL = process.env.NEXT_PUBLIC_MFE_SERVICE_DISCOVERY_URL;
    } else if (window.MFE_SERVICE_DISCOVERY_URL) {
      discoveryUrl = window.MFE_SERVICE_DISCOVERY_URL;
    }

    if (!discoveryUrl) {
      return Promise.resolve(null);
    }
    const response = await fetch(discoveryUrl);
    return response.json();
  };
  let cachedManifest: ManifestType | null = null;
  return async () => {
    // don't serve manifest from cache in server, to avoid restarting the server to get the latest manifest
    if (platform === 'server') {
      return await manifestPromise();
    } else if (cachedManifest !== null) {
      return structuredClone(cachedManifest);
    }
    cachedManifest = await manifestPromise();
    return structuredClone(cachedManifest);
  };
}

export type GetShareFromUnpkgArgumentType = {
  packageName: string;
  version: string;
  manifestPromise: () => Promise<ManifestType | null>;
};

export type getShareFromUnpkgType = (args: GetShareFromUnpkgArgumentType) => SharedGetter;

export type platformType = 'server' | 'browser';

export default function npmRuntimeGlobalPluginFactory(
  getShareFromUnpkg: getShareFromUnpkgType,
  platform: platformType,
): FederationRuntimePlugin {
  const getManifest = getManifestFactory(platform);
  return {
    name: 'dynamic-runtime-loader-plugin',
    async beforeRequest(args) {
      const manifest = await getManifest();
      const remotes = platform === 'server' ? manifest?.mfe.server : manifest?.mfe.client;
      if (!remotes) {
        console.error(`[Error] - dynamic-runtime-loader-plugin  - No remotes found in the manifest`);
        return args;
      }
      args.options.remotes = structuredClone(remotes);
      return args;
    },
    resolveShare: (args) => {
      if (args.pkgName?.includes('@rc-d2c/context-manager')) {
        const { shareScopeMap, scope, pkgName, version, resolver } = args;
        const currentPackageRef = shareScopeMap[scope][pkgName][version];
        args.resolver = () => {
          if (!useLocalShares.has(pkgName)) {
            const newPkgName = pkgName.replace('@rc-d2c/', '');
            currentPackageRef.get = getShareFromUnpkg({
              packageName: newPkgName,
              version,
              manifestPromise: getManifest,
            });
            useLocalShares.add(pkgName);
          }
          return resolver();
        };
      }

      return args;
    },
  };
}
